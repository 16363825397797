import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { capitalize, compact } from "lodash";
import { DateTime } from "luxon";

import { useAuth } from "@contexts/auth";
import useHasFeature from "@hooks/use-has-feature";
import { FeatureNames } from "@lib/constants/featureNames";
import { PackageType } from "@lib/data/schemas/packages";
import { countDiffDays, weekdays, weeklyOptions } from "@lib/packages";
import { getOrdinal } from "@lib/utils";

import SmallBanner from "@components/Banner/SmallBanner";
import IconWithDetails from "@components/DisplayDetails/IconWithDetails";
import Label from "@components/Form/Label";
import OptionItem from "@components/Form/OptionItem";
import SelectForm from "@components/Form/SelectForm";
import TextFieldForm from "@components/Form/TextFieldForm";
import AlertIcon from "@components/Icons/AlertIcon";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import MailIcon from "@components/Icons/MailIcon";
import SubscriptionInvoicingIcon from "@components/Icons/SubscriptionInvoicingIcon";
import { TooltipSize } from "@components/Tooltips/Tooltip";

const invoicingMethodOptions = [
  {
    value: "auto",
    title: "Automatic invoicing",
    description: "Charge clients automatically when invoices are approved",
    icon: CreditCardIcon,
  },
  {
    value: "manual",
    title: "Manual invoicing",
    description: "Send invoices to clients for manual payment when approved",
    icon: MailIcon,
  },
  {
    value: "subscription",
    title: "Subscription",
    description: "Automatically bill for usage on a rolling basis with a min",
    icon: SubscriptionInvoicingIcon,
  },
];

const monthlyOptions = [
  { label: "First day of the month", value: "first-of-month" },
  { label: "Last day of the month", value: "last-of-month" },
];

type PayPeriodType = "months" | "weeks";
type InputOptionType = { label: string; value: string };
type DataType = Record<
  PayPeriodType,
  {
    autoChargeDayLabel: string;
    cutOffDayLabel: string;
    cutOffDayOptions: InputOptionType[];
  }
>;

const data: DataType = {
  months: {
    autoChargeDayLabel: "Auto-charge day",
    cutOffDayLabel: "Cut-off date",
    cutOffDayOptions: monthlyOptions,
  },
  weeks: {
    autoChargeDayLabel: "Auto-charge day of the following week",
    cutOffDayLabel: "Cut off day of the week",
    cutOffDayOptions: weeklyOptions,
  },
};

const getAutoChargeDayOptions = (payPeriod: PayPeriodType) => {
  const options: InputOptionType[] = [];
  if (payPeriod === "months") {
    for (let i = 1; i <= 28; i++) {
      options.push({ label: `${getOrdinal(i)} of month`, value: `${i}` });
    }
  } else {
    for (const weekday of weekdays) {
      options.push({
        label: `The next ${capitalize(weekday)}`,
        value: weekday,
      });
    }
  }

  return options;
};

interface UsageInvoicingFormProps {
  usageInvoicing?: PackageType["usageInvoicing"];
}

const UsageInvoicingForm: FC<UsageInvoicingFormProps> = ({
  usageInvoicing,
}) => {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { oid } = useAuth();
  const [hasUBPSubscriptionFeature] = useHasFeature(
    oid,
    FeatureNames.usageBasedSubscription
  );

  const currentMethod = watch("usageInvoicing.method");
  const currentPeriod =
    (watch("usageInvoicing.period") as PayPeriodType) ?? usageInvoicing?.period;
  const currentCutOffDate = watch("usageInvoicing.cutOffDate");
  const currentAutoChargeDay = watch("usageInvoicing.autoChargeDay");
  const selectedData = data[currentPeriod ?? "months"];
  const isMonthly = currentPeriod === "months";

  const manualInvoicingMethod =
    currentMethod === "manual" ||
    (!currentMethod && usageInvoicing?.method === "manual");

  const subscriptionInvoicingMethod =
    currentMethod === "subscription" ||
    (!currentMethod && usageInvoicing?.method === "subscription");

  const periodOptions = compact([
    { label: "Monthly", value: "months" },
    { label: "Weekly", value: "weeks" },
  ]);

  const setDefaultCurrentWeekOptions = () => {
    const now = DateTime.now();
    const weekDayIndex = now.weekday - 1;
    // we set 48 hours as the suggestion for the auto charge day
    const autoChargeDayIndex = (weekDayIndex + 2) % 7;
    const currentWeekDay = weeklyOptions[weekDayIndex];
    const autoChargeDay = getAutoChargeDayOptions("weeks")[autoChargeDayIndex];
    setValue("usageInvoicing.cutOffDate", currentWeekDay.value);
    setValue("usageInvoicing.autoChargeDay", autoChargeDay.value);
  };

  const renderMethod = (
    <div>
      <Label className="mb-1">Invoicing method</Label>
      <Controller
        name="usageInvoicing.method"
        control={control}
        defaultValue={usageInvoicing?.method ?? invoicingMethodOptions[0].value}
        render={({ field }) => (
          <>
            {invoicingMethodOptions
              .filter((method) => {
                if (
                  method.value === "subscription" &&
                  !hasUBPSubscriptionFeature
                ) {
                  return false;
                }
                return true;
              })
              .map((option, index) => (
                <OptionItem
                  key={`package-option-type-${option.value}-${index}`}
                  title={option.title}
                  description={option.description}
                  icon={option.icon}
                  selected={field.value === option.value}
                  onClick={() => field.onChange(option.value)}
                />
              ))}
          </>
        )}
      />
    </div>
  );

  const renderPeriod = (
    <SelectForm
      name="usageInvoicing.period"
      label="Invoicing period"
      placeholder="Select invoicing period"
      defaultValue={usageInvoicing?.period ?? "months"}
      options={periodOptions.filter((option) => {
        if (option.value === "weeks" && subscriptionInvoicingMethod) {
          return false;
        }
        return true;
      })}
      control={control}
      onInputChange={(item) => {
        const value = item.value as PayPeriodType;
        const options = data[value].cutOffDayOptions;
        const existsOnCutOffDate = options.includes(currentCutOffDate);

        if (value === "weeks" && !existsOnCutOffDate) {
          setDefaultCurrentWeekOptions();
        }
        if (value === "months" && !existsOnCutOffDate) {
          setValue("usageInvoicing.cutOffDate", options[0].value);
          setValue(
            "usageInvoicing.autoChargeDay",
            getAutoChargeDayOptions("months")[0].value
          );
        }
      }}
    />
  );

  const renderCutOffDate = !subscriptionInvoicingMethod && (
    <SelectForm
      name="usageInvoicing.cutOffDate"
      label={selectedData.cutOffDayLabel}
      defaultValue={usageInvoicing?.cutOffDate ?? "first-of-month"}
      placeholder="Select cut-off date"
      options={selectedData.cutOffDayOptions}
      control={control}
    />
  );

  const renderAutoChargeDate = !manualInvoicingMethod &&
    !subscriptionInvoicingMethod && (
      <SelectForm
        name="usageInvoicing.autoChargeDay"
        label={selectedData.autoChargeDayLabel}
        tooltip={
          isMonthly
            ? undefined
            : "The autocharge date is always following the cutoff date"
        }
        tooltipSize={TooltipSize.small}
        defaultValue={usageInvoicing?.autoChargeDay?.toString() ?? "1"}
        placeholder="Select auto-charge day"
        control={control}
        options={getAutoChargeDayOptions(currentPeriod)}
      />
    );

  const renderDueAfterDays = manualInvoicingMethod && (
    <TextFieldForm
      label="Invoice due after"
      name="usageInvoicing.dueAfterDays"
      register={register}
      errors={errors}
      required
      isNumber
      defaultValue={usageInvoicing?.dueAfterDays ?? 0}
      errorMessage="Package price is required"
      otherInputProps={{ min: 0 }}
      helperChildClassName="text-grey-500"
      helper="Days after sending"
      helperClassName="bg-foreground/7 rounded-r-lg"
      containerClassName="!mb-0"
      type="number"
    />
  );

  const renderInfo = manualInvoicingMethod && (
    <IconWithDetails
      icon={<InfoIcon />}
      iconClassNames="bg-transparent !px-0 !text-blue-200"
      className="!py-0"
      wrapperClassNames="!pr-2 bg-blue-950 p-2 rounded-lg !gap-2"
      subtitleClassNames="!text-blue-200 text-xs"
      subtitle="Invoices generated for this package will be added to your queue, ready for approval before being charged to the client."
    />
  );

  const renderSubscriptionAmount = subscriptionInvoicingMethod && (
    <TextFieldForm
      isCurrency
      label={"Subscription amount"}
      placeholder="Enter subscription amount"
      name="usageInvoicing.subscriptionAmount"
      register={register}
      errors={errors}
      required
      defaultValue={usageInvoicing?.subscriptionAmount ?? 0}
      errorMessage="Subscription amount is required"
      otherInputProps={{ min: 1 }}
      isCurrencyErrorMessage="Amount should be a currency value of at least 1"
      helperChildClassName="px-[15px] text-foreground/500"
      helper="$"
      helperClassName="bg-foreground/7 rounded-l-lg"
      helperPrefix
      type="number"
    />
  );

  const renderWarningAutoChargeWeeks = () => {
    if (manualInvoicingMethod || isMonthly) return null;

    const diffDays = countDiffDays(currentCutOffDate, currentAutoChargeDay);

    if (diffDays > 1) return null;

    return (
      <SmallBanner
        variant="alert"
        items={[
          {
            Icon: AlertIcon,
            text: "Your auto-charge date is only 24 hours after the cut off date. Consider adding more time to allow for invoice approval.",
          },
        ]}
      />
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {renderMethod}
      {renderPeriod}
      {renderCutOffDate}
      {renderAutoChargeDate}
      {renderWarningAutoChargeWeeks()}
      {renderDueAfterDays}
      {renderSubscriptionAmount}
      {renderInfo}
    </div>
  );
};

export default UsageInvoicingForm;
