import { FC } from "react";
import classNames from "classnames";

interface SelectFrequencyProps {
  // todo type
  field: any;
  optionPrefix: string;
}

const SelectFrequency: FC<SelectFrequencyProps> = ({ field, optionPrefix }) => {
  const baseFieldsStyle =
    "text-black-ink border-grey-900 focus:outline-none focus:ring-0 focus:shadow-none focus:bg-white focus:border-grey-900";

  return (
    <div className="flex h-[47px]">
      <input
        className={classNames(
          "flex-1 text-right rounded-l-lg w-full",
          baseFieldsStyle
        )}
        type="number"
        min={0}
        placeholder="Enter a number"
        value={field.value?.total}
        onChange={(e) =>
          field.onChange({
            ...field.value,
            ...(!field.value?.type && { type: "months" }),
            total: Number(e.target.value),
          })
        }
      />
      <select
        className={classNames("rounded-r-lg border-l-0", baseFieldsStyle)}
        value={field.value?.type || "months"}
        onChange={(e) =>
          field.onChange({ ...field.value, type: e.target.value })
        }
      >
        <option value="weeks">{`${optionPrefix} per week`}</option>
        <option value="months">{`${optionPrefix} per month`}</option>
      </select>
    </div>
  );
};

export default SelectFrequency;
