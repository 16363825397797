import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { PackageType } from "@lib/data/schemas/packages";

import Label from "@components/Form/Label";
import OptionItem from "@components/Form/OptionItem";
import PathIcon from "@components/Icons/PathIcon";
import RippleIcon from "@components/Icons/RippleIcon";

const depositRuleOptions = [
  {
    value: "start-of-engagement",
    title: "Apply to start of engagement",
    description: "Sessions draw drown from a balance",
    icon: RippleIcon,
  },
  {
    value: "end-of-engagement",
    title: "Apply to the end of engagement",
    description: "Deposit if applied to final month of service",
    icon: PathIcon,
  },
];

interface DepositRuleFormProps {
  depositRule?: PackageType["depositRule"];
}

export const DepositRuleForm: FC<DepositRuleFormProps> = ({ depositRule }) => {
  const { control } = useFormContext();

  const renderMethod = (
    <div>
      <Label className="mb-1">Deposit rule</Label>
      <Controller
        name="depositRule"
        control={control}
        defaultValue={depositRule ?? depositRuleOptions[1].value}
        render={({ field }) => (
          <>
            {depositRuleOptions.map((option, index) => (
              <OptionItem
                key={`package-deposit-rule-${option.value}-${index}`}
                title={option.title}
                description={option.description}
                icon={option.icon}
                selected={field.value === option.value}
                onClick={() => field.onChange(option.value)}
              />
            ))}
          </>
        )}
      />
    </div>
  );

  return <div className="flex flex-col gap-4">{renderMethod}</div>;
};
