import { FC } from "react";
import classNames from "classnames";
import { toNumber } from "lodash";

import { FormDataSplitType } from "@lib/data/schemas/packages";
import { getFormatPeriod } from "@lib/packages";
import { displayItemAmountString } from "@lib/products";
import { getOrdinal } from "@lib/utils";

import ClockIcon from "@components/Icons/ClockIcon";
import NewPackageIcon from "@components/Icons/NewPackageIcon";
import PaymentOptionPreviewItem from "@components/Package/PaymentOptionPreviewItem";

interface PackagePaymentOptionPreviewProps {
  className?: string;
  total: number;
  currency: string;
  splits?: FormDataSplitType[];
}

const PackagePaymentOptionPreview: FC<PackagePaymentOptionPreviewProps> = ({
  className,
  total,
  currency,
  splits,
}) => {
  return (
    <div className={classNames("bg-foreground/7 rounded-lg p-4", className)}>
      {splits?.length && (
        <div className="border-b mb-4 pb-4">
          {splits.map((split, index) => {
            const isDueAtBooking = split?.dueOn == 0 && index < 1;
            const title = isDueAtBooking
              ? "Deposit due at booking"
              : getFormatPeriod(split.dueOn, split.period);
            const subtitle = isDueAtBooking
              ? "Charged immediately"
              : "Scheduled automatically";
            const icon = isDueAtBooking ? (
              <NewPackageIcon className="text-accent h-6 w-6" />
            ) : (
              <ClockIcon className="text-foreground/500 h-4 w-4" />
            );
            return (
              <PaymentOptionPreviewItem
                key={`split_preview_${index}`}
                title={
                  isDueAtBooking ? title : `${getOrdinal(index + 1)} ${title}`
                }
                subtitle={subtitle}
                icon={icon}
                splitTotal={
                  displayItemAmountString(split.total, currency) as string
                }
                isDueAtBooking={isDueAtBooking}
              />
            );
          })}
        </div>
      )}
      <div className="flex justify-between items-center w-full">
        <h3 className={classNames("font-medium text-right text-foreground")}>
          Total payment
        </h3>
        <div className="text-right">
          <div className={classNames("font-medium text-foreground")}>
            {displayItemAmountString(toNumber(total), currency)}
          </div>
          <span className="text-xs text-foreground/500">Taxes included</span>
        </div>
      </div>
    </div>
  );
};

export default PackagePaymentOptionPreview;
