import { FC, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import classNames from "classnames";

import { formatPrice } from "@lib/utils";

import { ErrorMessage } from "@components/ErrorMessage";
import TextFieldForm from "@components/Form/TextFieldForm";
import InfoFilledIcon from "@components/Icons/InfoFilledIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import { SchedulerItem } from "@components/SchedulerPicker";

import { SchedulerWithItemType } from "./PackageForm";
import { PackageFormData } from "./PackageSchedulersPicker";
import SelectFrequency from "./SelectFrequency";

interface PackageSchedulerItemProps {
  scheduler: SchedulerWithItemType;
  remove?: () => void;
  index: number;
  withQuantityField?: boolean;
  withFrequencyField?: boolean;
  withPriceField?: boolean;
  showIcon?: boolean;
  modify?: boolean;
  customNumberValidation?: (value: number) => string | undefined;
  positiveNumberOnly?: boolean;
  wrapperClassNames?: string;
}

const PackageSchedulerItem: FC<PackageSchedulerItemProps> = ({
  scheduler,
  remove,
  index,
  withQuantityField = true,
  withPriceField = false,
  withFrequencyField = false,
  showIcon = false,
  modify,
  customNumberValidation,
  positiveNumberOnly = true,
  wrapperClassNames,
}) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useFormContext<PackageFormData>();

  const contentType = watch("contentType");
  const isSessions = contentType === "sessions";
  const optionPrefix = isSessions ? "sessions" : "hours";
  const currency = watch("currency");
  const watchAmount = watch(`items.${index}.usagePricing.amount`);
  const [amount, setAmount] = useState<number>(
    watchAmount ?? scheduler?.usagePricing?.amount ?? 0
  );
  const calculatedPricePerSession = useMemo(() => {
    if (!amount) return;
    const rawAmount = (amount ?? 0) / 60;
    const result = rawAmount * scheduler.duration * 100;
    return result;
  }, [amount, scheduler?.duration]);
  const shouldShowPricePerSession =
    withPriceField && scheduler.duration !== 60 && !!calculatedPricePerSession;

  const customValidation = (value: number) => {
    if (value < 0) return "Must be a positive number";
    if (value % 1 != 0) return "Must be a whole number";
    if (modify) return;
    if (value < 1) return "One appointment minimum required";
    return;
  };

  const quantityError = errors?.items?.[index]?.quantity?.message;
  const amountError = errors?.items?.[index]?.usagePricing?.amount?.message;

  if (!scheduler) return null;

  const renderSchedulerPriceSessionPreview = shouldShowPricePerSession && (
    <div className="flex items-center text-sm text-grey-500 mb-3 space-x-1">
      <InfoFilledIcon className="text-grey-800" />
      <span>
        Equals{" "}
        <span className="font-medium text-black-ink">
          {formatPrice(calculatedPricePerSession, currency)}
        </span>{" "}
        per session
      </span>
    </div>
  );

  return (
    <>
      <div
        className={classNames(
          "border rounded-lg mb-2 w-full",
          wrapperClassNames
        )}
      >
        <div className="flex w-full items-center divide-x">
          {withQuantityField && (
            <TextFieldForm
              type="number"
              name={`items.[${index}].quantity`}
              register={register}
              required
              customValidation={customNumberValidation ?? customValidation}
              containerClassName="w-[70px] shrink-0 flex justify-center items-center"
              inputClassName="border-none hover:bg-transparent text-center pl-5 pr-2 text-lg"
              min={positiveNumberOnly ? "0" : undefined}
            />
          )}
          <div className="flex justify-between items-center truncate px-4 py-3 w-full">
            <SchedulerItem {...scheduler} showIcon={showIcon} />
            {remove && (
              <button
                className="bg-peach-950 text-peach-500 p-2 ml-2 rounded-lg"
                onClick={() => remove()}
              >
                <TrashIcon />
              </button>
            )}
          </div>
        </div>
        {withFrequencyField && (
          <div className="px-4 pb-3">
            <h3 className="mb-1 text-grey-500 text-sm">{`Suggested ${optionPrefix}`}</h3>
            <Controller
              name={`items.[${index}].suggestedFrequency`}
              control={control}
              rules={{
                validate: (value) => {
                  if (!value) return "Frequency is required";
                  return true;
                },
              }}
              render={({ field }) => (
                <SelectFrequency field={field} optionPrefix={optionPrefix} />
              )}
            />
          </div>
        )}
        {withPriceField && (
          <div className="px-4">
            <TextFieldForm
              type="number"
              name={`items.[${index}].usagePricing.amount`}
              register={register}
              required
              customValidation={(v: number) => {
                if (!v) return "Price is required";
                if (v < 0) return "Must be a positive number";
                return true;
              }}
              defaultValue={scheduler?.usagePricing?.amount || 0}
              errorMessage="Price is required"
              containerClassName="w-full"
              placeholder="Enter scheduler price"
              onChange={(e) => {
                setAmount(Number(e.target.value));
              }}
              inputClassName="rounded-l-none"
              min="0"
              helperChildClassName="px-3 text-grey-500"
              helper={currency}
              helperClassName="bg-foreground/7 rounded-l-lg h-[40px]"
              helperPrefix
              rightComponent={
                <span className="mt-1 flex items-center h-[40px] bg-foreground/7 border border-grey-light border-l-0 px-3 whitespace-nowrap text-grey-500 text-sm rounded-r-md">
                  hour
                </span>
              }
            />
            {renderSchedulerPriceSessionPreview}
          </div>
        )}
      </div>
      {quantityError && (
        <ErrorMessage className="mb-1">{quantityError}</ErrorMessage>
      )}
      {amountError && (
        <ErrorMessage className="mb-1">{amountError}</ErrorMessage>
      )}
    </>
  );
};

export default PackageSchedulerItem;
