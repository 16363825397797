import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const SubscriptionInvoicingIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6218 7.08626L14.9921 7.71597C14.8448 5.0867 12.6661 3 10 3C7.23858 3 5 5.23858 5 8C5 10.7614 7.23858 13 10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11C8.34315 11 7 9.65685 7 8C7 6.34315 8.34315 5 10 5C11.5474 5 12.8211 6.17152 12.9827 7.67599L12.393 7.08626C12.1977 6.891 11.8811 6.891 11.6859 7.08626L11.1183 7.65381C10.9231 7.84907 10.9231 8.16565 11.1183 8.36091L13.6538 10.8964C13.8491 11.0917 14.1657 11.0917 14.3609 10.8964L16.8964 8.36092C17.0917 8.16565 17.0917 7.84907 16.8964 7.65381L16.3289 7.08626C16.1336 6.89099 15.817 6.89099 15.6218 7.08626Z"
        fill="#040506"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H20C21.1046 0 22 0.895431 22 2V14C22 15.1046 21.1046 16 20 16H2C0.89543 16 0 15.1046 0 14V2ZM20 4H17.5C17.2239 4 17 4.22386 17 4.5V6.5C17 6.77614 17.2239 7 17.5 7H20V14H2L2 7H3.5C3.77614 7 4 6.77614 4 6.5V4.5C4 4.22386 3.77614 4 3.5 4H2L2 2H20V4Z"
        fill="#040506"
      />
    </svg>
  );
};

export default SubscriptionInvoicingIcon;
